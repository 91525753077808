/* eslint-disable no-undef */
import { AAva, CAva, FAva, TAva } from "./StockComponents/ProductIcons";
import { AdapterPop } from "./home/AdapterPop";
import { ChillerPop } from "./home/ChillerPop";
import { TubPop } from "./home/TubPop";

export const productsArray = [
    {
        id: 0,
        idStripe: process.env.REACT_APP_PRICE_ID_0,
        title: 'AZ-Adapt',
        text: 'Any Tub - Anywhere',
        imgsrc: '/images/bath01.webp',
        price: 2495,
        noSalePrice: null,
        numberOfReviews: 44,
        rating: 4.5,
        url: 'products/hydrosync-2-0',
        productDescription: 'Meet the AZ-Adapt bundle, the first-ever cold plunge system for your home bathtub. Built from durable 8-gauge aluminum, the adapter extends up to 7 inches to fit almost any tub. Our powerful 0.8hp chiller cools water by 4-8 degrees per hour, reaching as low as 37°F. Versatile and DIY-friendly, use AZ-Adapt with a stock tank, chest freezer, cooler, or any container you have. ',
        icons: [AAva, CAva, FAva],
        includes: ['/images/chillerT01.webp', '/images/adapterT01.webp'],
        productImages: ['/images/productImages/AZ-Adapt02', '/images/productImages/AZ-Adapt01', '/images/productImages/AZ-Adapt03', '/images/productImages/AZ-Adapt05', '/images/productImages/AZ-Adapt06', '/images/productImages/AZ-Adapt07']
    },
    {
        id: 1,
        idStripe: process.env.REACT_APP_PRICE_ID_1,
        title: 'AZ-Inflate',
        text: 'Dedicated Cold Plunge',
        imgsrc: '/images/chillerTub02.webp',
        price: 2795,
        noSalePrice: null,
        numberOfReviews: 21,
        rating: 5,
        url: 'products/revive-nx',
        productDescription: 'Introducing the AZ-Inflate bundle. Set up a dedicated cold plunge in minutes. Built from PVC drop stitch the inflatable tub ensures unmatched strength and stability.  The AZ-Air bundle includes our powerful .8hp chiller, cooling the water 4-8 degrees per hour reaching temperatures as low as 37°F. Stay consistent with a dedicated plunging setup, right at home.',
        icons: [TAva, CAva, FAva],
        includes: ['/images/chillerT01.webp', '/images/adapterT01.webp'],
        productImages: ['/images/productImages/AZ-Inflate01', '/images/productImages/AZ-Inflate02', '/images/productImages/AZ-Inflate03', '/images/productImages/AZ-Inflate04', '/images/productImages/AZ-Inflate05', '/images/productImages/AZ-Inflate06', '/images/productImages/AZ-Inflate07']
    },
    {
        id: 2,
        idStripe: process.env.REACT_APP_PRICE_ID_2,
        title: 'AZ-Complete',
        text: 'The Ultimate Bundle',
        imgsrc: '/images/allThree01.webp',
        price: 2995,
        noSalePrice: null,
        numberOfReviews: 65,
        rating: 4.5,
        url: 'products/oasis-pro',
        productDescription: 'Take your cold-water therapy to the next level with AZ-Complete. This all-in-one bundle includes everything you need to plunge at home or on the go. Featuring a powerful 0.8hp chiller, a versatile 7-inch adapter, and a spacious 150-gallon tub, AZ-Complete equips you for the perfect plunge experience—whether in the bath or on the deck, this bundle has you covered.',
        icons: [AAva, TAva, CAva, FAva],
        includes: ['/images/chillerT01.webp', '/images/adapterT01.webp'],
        productImages: ['/images/productImages/AZ-Complete01', '/images/productImages/AZ-Complete02', '/images/productImages/AZ-Inflate04', '/images/productImages/AZ-Adapt04', '/images/productImages/AZ-Adapt07']
    },
    {
        id: 3,
        idStripe: process.env.REACT_APP_PRICE_ID_3,
        title: 'Adapter',
        text: 'Any tub, anywhere.',
        imgsrc: '/images/adapter01.webp',
        imgsrcT: '/images/adapterT01.webp',
        price: 345,
        noSalePrice: 495,
        numberOfReviews: 44,
        rating: 4.5,
        dialog: AdapterPop,
        url: 'products/adapter',
        productDescription: 'Introducing the world\'s first cold plunge adapter. Adjustable up to 7 inches, the adapter turns anything into a cold plunge in minutes. Built from 8-gauge aluminum with a black anodized finish and steel faucets, the adapter is designed to fit perfectly in your bathroom while being rugged enough for on-the-go use. Convert bathtubs, stock tanks, DIY plunges, and more.',
        icons: [AAva],
        includes: ['/images/chillerT01.webp', '/images/adapterT01.webp'],
        productImages: ['/images/productImages/AZ-Adapter01', '/images/productImages/AZ-Adapter02', '/images/productImages/AZ-Adapter03', '/images/productImages/AZ-Adapt04', '/images/productImages/AZ-Adapt05', '/images/productImages/AZ-Adapt06', '/images/productImages/AZ-Adapt07']
    },
    {
        id: 4,
        idStripe: process.env.REACT_APP_PRICE_ID_4,
        title: 'Chiller',
        text: 'Year round cooling.',
        imgsrc: '/images/chiller01.webp',
        imgsrcT: '/images/chillerT01.webp',
        price: 1995,
        noSalePrice: null,
        numberOfReviews: 65,
        rating: 4.5,
        dialog: ChillerPop,
        url: 'products/chiller',
        productDescription: 'Meet the 0.8hp pro chiller—your powerhouse for cold water therapy. Encased in steel and powered by GMCC compressors, the same top-tier technology found in high-end chilling equipment. The chiller cools as low as 37°F, dropping temperatures by up to 8 degrees per hour starting at 70°F. Pair it with the adapter, tub, or your own DIY setup for unmatched efficiency and reliability.',
        icons: [CAva, FAva],
        includes: ['/images/chillerT01.webp', '/images/adapterT01.webp'],
        productImages: ['/images/productImages/AZ-Chiller01', '/images/productImages/AZ-Chiller02', '/images/productImages/AZ-Chiller03', '/images/productImages/AZ-Chiller04', '/images/productImages/AZ-Chiller05']
    },
    {
        id: 5,
        idStripe: process.env.REACT_APP_PRICE_ID_5,
        title: 'Tub',
        text: 'Dedicated plunge.',
        imgsrc: '/images/tub01.webp',
        imgsrcT: '/images/tubT01.webp',
        price: 995,
        noSalePrice: null,
        numberOfReviews: 21,
        rating: 5,
        dialog: TubPop,
        url: 'products/tub',
        productDescription: 'Engineered from the same high-performance materials trusted in industry-leading paddle boards and whitewater rafts, the AZ Tub delivers lasting resilience. Featuring 3-inch thick drop-stitch walls and a triple-layered PVC bottom, it comfortably accommodates everyone. Effortlessly deflate and store the tub in the included backpack for seamless portability and storage. ',
        icons: [TAva],
        includes: ['/images/chillerT01.webp', '/images/adapterT01.webp'],
        productImages: ['/images/productImages/AZ-Tub01', '/images/productImages/AZ-Tub02', '/images/productImages/AZ-Tub03', '/images/productImages/AZ-Tub04']

    }
];

export function getProductData(id) {
    let productData = productsArray.find(product => product.id === id);

    if (productData === undefined) {
        console.log("Product data does not exist for ID: " + id);
        return undefined;
    }

    return productData;
}
